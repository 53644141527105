import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import 'lib-flexible'
import store from './store/index'
// import './assets/font/font.css'
import 'vue-area-linkage/dist/index.css';
import {AreaCascader} from 'vue-area-linkage';
import {AreaSelect} from 'vue-area-linkage';


Vue.config.productionTip = false
Vue.use(AreaCascader)
Vue.use(AreaSelect)

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
